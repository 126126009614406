import React from "react";
import { Table } from "react-bootstrap";

function Staff() {
  return (
    <Table className="table table-bordered">
      <thead>
        <tr>
          <th>SLNO.</th>
          <th>FACULTY</th>
          <th>COUNT</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Principal</td>
          <td>1</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Total No of Teachers</td>
          <td>32</td>
        </tr>
        <tr>
          <td></td>
          <td>PGT</td>
          <td>11</td>
        </tr>
        <tr>
          <td></td>
          <td>TGT</td>
          <td>12</td>
        </tr>
        <tr>
          <td></td>
          <td>PRT</td>
          <td>9</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Teachers Section Ratio</td>
          <td>1 : 3 : 1</td>
        </tr>
        <tr>
          <td>4</td>
          <td>DETAILS OF SPECIAL EDUCATOR</td>
          <td>-</td>

          </tr>
          <tr>
          <td>5</td>
          <td>DETAILS OF COUNSELLOR AND WELNESS TEACHER </td>
          <td>-</td>

          </tr>
        <tr>
          <td>6</td>
          <td>Sanskrit Teacher</td>
          <td>1</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Instructor SUPW</td>
          <td>1</td>
        </tr>
        <tr>
          <td>8</td>
          <td>Language Teacher</td>
          <td>1</td>
        </tr>
        <tr>
          <td>9</td>
          <td>JBT</td>
          <td>6</td>
        </tr>
        <tr>
          <td>10</td>
          <td>DIM</td>
          <td>2</td>
        </tr>
        <tr>
          <td>11</td>
          <td>PTI</td>
          <td>1</td>
        </tr>
        <tr>
          <td>12</td>
          <td>Librarian</td>
          <td>1</td>
        </tr>
        <tr>
          <td>13</td>
          <td>LA</td>
          <td>2</td>
        </tr>
        <tr>
          <td>14</td>
          <td>Tabla Player</td>
          <td>1</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default Staff;
