import React, { Component } from "react";
import HeaderTwo from "./components/HeaderTwo";
import ServiceBox from "./components/ServiceBox";
import NumberCounter from "./components/NumberCounter";
import TeamSlider from "./components/TeamSlider";
import TestimonialSlider from "./components/TestimonialSlider";
import HomeBlog from "./components/HomeBlog";
import FooterTwo from "./components/FooterTwo";
import HeroSlider from "./components/HeroSlider";
import CampusTour from "./components/CampusTour";
import FaqEvent from "./components/FaqEvent";
import PublicDisclosure from "./components/PublicDisclosure";
import ResultsandAcademics from './components/ResultsandAcademics';

export default class HomeTwo extends Component {
  render() {
    return (
      <div className="main-wrapper">
        <HeaderTwo />

        <HeroSlider />

        <ServiceBox />

        <TeamSlider />

        <TestimonialSlider />

        <FaqEvent />

        <NumberCounter />

        <HomeBlog />

        <PublicDisclosure />

        <ResultsandAcademics />

        <CampusTour />

        <FooterTwo />
      </div>
    );
  }
}
