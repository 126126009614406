import React from "react";
import { Table } from "react-bootstrap";

function Documents() {
  return (
    <Table className="table table-bordered">
      <thead>
        <tr>
          <th>SLNO.</th>
          <th>DOCUMENTS/INFORMATION</th>
          <th>UPLOAD DOCUMENTS</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>
            COPIES OF AFFILIATION/UGRADATION LETTER AND RECENT EXTENSION OF
            AFFILIATION,IF ANY
          </td>
          <td className={"text-center"}>
            <img src={"/assets/images/check.svg"} />
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>
            COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION /RENEWAL CERTIFICATE
            AS APPLICABLE
          </td>
          <td className={"text-center"}>
            <img src={"/assets/images/delete.svg"} />
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>
            COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED,IF APPLICABLE,BY THE
            STATE GOVT /UT
          </td>
          <td className={"text-center"}>
            -
            {/* <img src={"/assets/images/delete.svg"} /> */}
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>
            COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT. 2009, AND IT'S
            RENEWAL IF APPLICABLE
          </td>
          <td className={"text-center"}>-
            {/* <img src={"/assets/images/delete.svg"} /> */}
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>
            COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIOAL
            BUILDING CODE{" "}
          </td>
          <td className={"text-center"}>
            <img src={"/assets/images/check.svg"} />
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>
            COPY OF VALID FIRE CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY
          </td>
          <td className={"text-center"}>
            <img src={"/assets/images/check.svg"} />
          </td>
        </tr>
        <tr>
          <td>7</td>
          <td>
            COPY OF THE DE0 CERTIFICATE SUBMITTED BY THE SCHOOL FOR
            AFFILIATION/UPGRADATON/EXTENSION OF AFFILIATION OR SELF CERTIEIATION
            BY SCHOOL
          </td>
          <td className={"text-center"}>
            -
            {/* <img src={"/assets/images/check.svg"} /> */}
          </td>
        </tr>
        <tr>
          <td>8</td>
          <td>COPIES OF VALID WATER HEALTH AND SANITATION CERTIFICATES</td>
          <td className={"text-center"}>
            <img src={"/assets/images/check.svg"} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default Documents;
