export const res = {
  general: {
    logo: "/assets/images/local/logo.png",
    phone: "01907-262271 ,9418052070",
    address: "BBMB Colony, Sundar Nagar, Himachal Pradesh 175019",
    email: "44301@cbseskikska.in",
    social_links: {
      instagram: "",
      facebook: "",
      twitter: "",
      linkedIn: "",
      whatsapp: "",
    },
  },
  slider: [
    {
      id: 1,
      heading: "Welcome ",
      heading_color: "",
      sub_heading:
        "Govt. Sr. Sec. Model School, BSL Project Sundernagar T/ship (H.P.)",
      sub_heading_color: "",
      image: "local/1.jpeg",
      uniqClass: "slider-box slider-box1",
      btnOneLink: "course-grid",
      btnTwoLink: "contact",
    },
    {
      id: 2,
      heading: "Welcome .",
      heading_color: "",
      sub_heading:
        "Govt. Sr. Sec. Model School, BSL Project Sundernagar T/ship (H.P.)",
      sub_heading_color: "",
      image: "local/3.jpeg",
      uniqClass: "slider-box slider-box2 text-right",
      btnOneLink: "course-grid",
      btnTwoLink: "contact",
    },
    {
      id: 2,
      heading: "Welcome .",
      heading_color: "",
      sub_heading:
        "Govt. Sr. Sec. Model School, BSL Project Sundernagar T/ship (H.P.)",
      sub_heading_color: "",
      image: "local/9.jpeg",
      uniqClass: "slider-box slider-box2 text-right",
      btnOneLink: "course-grid",
      btnTwoLink: "contact",
    },
  ],

  business_overview: {
    main_heading: "Checkout Our Features",
    dataList: [
      {
        id: 1,
        heading: "Medical, Non-medical and Commerce",
        heading_color: "",
        sub_heading: "",
        sub_heading_color: "",
        icon: "flaticon-thumbs-like",
      },
      {
        id: 2,
        heading: "Modern Library",
        heading_color: "",
        sub_heading: "",
        sub_heading_color: "",
        icon: "flaticon-reading",
      },
      {
        id: 3,
        heading: "Qualified Teacher",
        heading_color: "",
        sub_heading: "",
        sub_heading_color: "",
        icon: "flaticon-teacher",
      },
      {
        id: 4,
        heading: "NCC",
        heading_color: "",
        sub_heading: "",
        sub_heading_color: "",
        icon: "flaticon-unlimited",
      },
      {
        id: 5,
        heading: "NSS",
        heading_color: "",
        sub_heading: "",
        sub_heading_color: "",
        icon: "flaticon-locations",
      },
      {
        id: 6,
        heading: "Teaching Methodology",
        heading_color: "",
        sub_heading: "",
        sub_heading_color: "",
        icon: "flaticon-support",
      },
    ],
  },
  achievements: {
    main_heading: "Lets Have A Look At Some Of Achievements For Years We Get.",
    backgroundImage: "vd-bg.jpg",
    dataList: [
      {
        icon: "las la-graduation-cap",
        title: "Total Students",
        end: "850",
        duration: "5",
        delay: "1.5",
      },
      {
        icon: "las la-award",
        title: "Acedemic Awards ",
        end: "590",
        duration: "5",
        delay: "1.5",
      },
      {
        icon: "las la-university",
        title: "Sports",
        end: "10",
        duration: "5",
        delay: "1.5",
      },
      {
        icon: "las la-book-reader",
        title: "Library Books",
        end: "3790",
        duration: "5",
        delay: "1.5",
      },
    ],
  },
  events: [
    {
      id: 1,
      date: "25 March",
      title: "2020 admidion fair going on this week",
      time: "5:30pm - 7.00pm",
      location: "Newyork,NY.",
      desc: "Lorem ipsum dolor sit amet consectetur. Como Ssee hou",
      link: "/event-details",
    },
    {
      id: 2,
      date: "25 March",
      title: "2020 admidion fair going on this week",
      time: "5:30pm - 7.00pm",
      location: "Newyork,NY.",
      desc: "Lorem ipsum dolor sit amet consectetur. Como Ssee hou",
      link: "/event-details",
    },
    {
      id: 3,
      date: "25 March",
      title: "2020 admidion fair going on this week",
      time: "5:30pm - 7.00pm",
      location: "Newyork,NY.",
      desc: "Lorem ipsum dolor sit amet consectetur. Como Ssee hou",
      link: "/event-details",
    },
  ],
  testimonials: {
    backgroundImage: "vd-bg.jpg",
    dataList: [
      {
        id: 1,
        heading: "",
        comment:
          "With immense pleasure, I welcome one and all to the Govt. Sr. Sec. Model School BSL  Project BBMB Sundernagar (CBSE affiliated). A prominent institution under BBMB  organisation ( ISO Certified) for pursuing its educational objectives with emphasis on creation of new ideas, technologies, and knowledge in the fields of teaching learning process .Over the years, acquisition of modern facilities, as well as equipment, has aided and stimulated our educational objectives successfully , which will continue to scale up in future.<br/>We should not only take this Pandemic as problem but a way to pace life differently i.e, techno learning. During this period we made our best efforts to make virtual classes effective.<br/><br/> The present is witnessing a steady decline in selection of students in Govt. Institutes after 12th due to emerging private sector in the field and non availability of professional coaching , there is a huge demand for the same. We thus try to shift our approach to mission mode projects emphasising on relevant knowledge based solutions. Harnessing knowledge in any form, be it; private, strategic, public, or social is paramount for the development of a nation.<br/><br/> Govt. Sr.Sec. Model School BSL Project BBMB Sundernagar , School under BBMB organisation has strong foundations with transformed approach and a committed work force of dedicated  Teachers and support staff that is aligned with School requirements . I heartily welcome for your constructive suggestions and participation to realize the visions of School. Together we shall do it, what we aspire to be",
        image: "/local/localGallery/17.jpeg",
        title: "Principal",
      },
    ],
  },
  gallery: [{ title: "", url: "" }],
};
