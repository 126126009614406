import React from "react";
import { Table } from "react-bootstrap";

function Resultclassx() {
    return (
        <Table className="table table-bordered">
            <thead>
                <tr>
                    <th>S.NO.</th>
                    <th>YEAR</th>
                    <th>NO. OF REGISTERED STUDENTS</th>
                    <th>NO.OF STUDENTS PASSED</th>
                    <th>PASS PERCENTAGE</th>

                </tr>

            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>2018-19</td>
                    <td >68</td>
                    <td >68</td>
                    <td>100%</td>
                    <td ></td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>2019-20</td>
                    <td>66</td>
                    <td >66</td>
                    <td >100%</td>
                    <td ></td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>2020-21</td>
                    <td>70</td>
                    <td >70</td>
                    <td >100%</td>
                    <td ></td>
                </tr>
            </tbody>
        </Table>
    );
}

export default Resultclassx;
