import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Styles } from "../pages/shop/styles/productDetails";
import { Style } from "./styles/homeBlog";
import Documents from "./Results-Academics/Documents";
import Resultclassxii from "./Results-Academics/Resultclass12";
import Resultclassx from "./Results-Academics/Resultclass10";

export default class ResultsandAcademics extends Component {
  render() {
    return (
      <Styles>
        <Style>
          <div className="main-wrapper product-details-page">
            <section className="product-details-area">
              <Container>
                <Row>
                  <Col className="col-md-12">
                    <section class="home-blog-area" style={{ padding: 0 }}>
                      <div className="sec-title text-center">
                        <h4 style={{ marginBottom: 0 }}>
                          RESULT AND ACADEMICS
                        </h4>
                      </div>
                    </section>
                  </Col>
                  <Col md="12">
                    <div className="product-tab">
                      <Tab.Container defaultActiveKey="documents">
                        <Nav className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="documents">Documents</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="resultx">
                              Result Class: X
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="resultxii">
                              Result Class: XII
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <div style={{float:'right'}}>
                          <a href="/assets/doc/documents.pdf" target="_blank" >
                            Documents and information of public disclosure
                            <img title="documents and information of public disclosure" src="/assets/images/download.svg" />
                          </a>
                          <a href="/assets/doc/result.pdf" target="_blank">
                            {" "}
                            Results and Academics of public disclosure
                            <img title="Results and Academics of public disclosure"  src="/assets/images/download.svg" />
                          </a>
                        </div>
                        <Tab.Content>
                          <Tab.Pane
                            eventKey="documents"
                            className="additional-tab"
                          >
                            <Documents />
                          </Tab.Pane>

                          <Tab.Pane
                            eventKey="resultx"
                            className="additional-tab"
                          >
                            <Resultclassx />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="resultxii"
                            className="additional-tab"
                          >
                            <Resultclassxii />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </Style>
      </Styles>
    );
  }
}
