import React from "react";
import { Table } from "react-bootstrap";

function Documents() {
    return (
        <Table className="table table-bordered">
            <thead>
                <tr>
                    <th>S.NO.</th>
                    <th>DOCUMENTS/INFORMATION</th>
                    <th>UPLOAD DOCUMENTS</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>
                        FEE STRUCTURE OF THE SCHOOL
                    </td>
                    <td className={'text-center'}><img src={'/assets/images/check.svg'} /></td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>
                        ANNUAL ACADEMIC CALANDER
                    </td>
                    <td className={'text-center'}><img src={'/assets/images/check.svg'} /></td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>
                        LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)
                    </td>
                    <td className={'text-center'}><img src={'/assets/images/check.svg'} /></td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>
                        LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS
                    </td>
                    <td className={'text-center'}>
                        -
                         {/* <img src={'/assets/images/check.svg'} /> */}
                         </td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>
                        PAST THREE- YEAR RESULT Or THE BOARD EXAMINATION AS
                        PER APPLCABLILTY
                    </td>
                    <td className={'text-center'}>
                         {/* <img src={'/assets/images/check.svg'} /> */}
                         </td>
                </tr>
            </tbody>
        </Table>
    );
}

export default Documents;
