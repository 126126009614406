import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Styles } from "../pages/shop/styles/productDetails";
import { Style } from "./styles/homeBlog";
import GeneralInformation from "./public-disclouser/general-information";
import Documents from "./public-disclouser/documents";
import Staff from "./public-disclouser/staff";
import Infrastructure from "./public-disclouser/infrastructure";
import Studentsandarea from "./public-disclouser/studentsandarea";

export default class PublicDisclosure extends Component {
  render() {
    return (
      <Styles>
        <Style>
          <div className="main-wrapper product-details-page">
            <section className="product-details-area">
              <Container>
                <Row>
                  <Col className="col-md-12">
                    <section class="home-blog-area" style={{ padding: 0 }}>
                      <div className="sec-title text-center">
                        <h4 style={{ marginBottom: 0 }}>
                          Mandatory Public Disclosure
                        </h4>
                      </div>
                    </section>
                  </Col>
                  <Col md="12">
                    <div className="product-tab">
                      <Tab.Container defaultActiveKey="description">
                        <Nav className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="description">
                              General Information
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="additional">
                              Documents And Information
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="staff">
                              Staff
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Infrastructure">
                              Infrastructure
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="studentsandarea">
                              Studentsandarea
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>

                        <Tab.Content>
                          <Tab.Pane
                            eventKey="description"
                            className="description-tab"
                          >
                            <GeneralInformation />
                          </Tab.Pane>

                          <Tab.Pane
                            eventKey="additional"
                            className="additional-tab"
                          >
                            <Documents />
                          </Tab.Pane>

                          <Tab.Pane
                            eventKey="staff"
                            className="additional-tab"
                          >
                            <Staff />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="Infrastructure"
                            className="additional-tab"
                          >
                            <Infrastructure />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="studentsandarea"
                            className="additional-tab"
                          >
                            <Studentsandarea />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </Style>
      </Styles>
    );
  }
}
