import React from "react";
import { Table } from "react-bootstrap";

function Studentsandarea() {
    return (
        <Table className="table table-bordered">
            <thead>
                <tr>
                    <th>S.NO.</th>
                    <th>CLASS</th>
                    <th>NO OF STUDENTS PER SECTION</th>
                    <th>SIZE OF ROOM</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>I-A </td>
                    <td>38</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>I-B</td>
                    <td>37</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>II-A </td>
                    <td>40</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>II-B</td>
                    <td>39</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>III-A </td>
                    <td>40</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>III-B </td>
                    <td>40</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>IV-A </td>
                    <td>38</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>IV-B </td>
                    <td>40</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>V-A </td>
                    <td>37</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>V-B </td>
                    <td>39</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>11</td>
                    <td>VI-A </td>
                    <td>40</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>12</td>
                    <td>VI-B </td>
                    <td>39</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>13</td>
                    <td>VII-A </td>
                    <td>40</td>
                    <td>5.53 X 5.4 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>14</td>
                    <td>VII-B </td>
                    <td>40</td>
                    <td>5.53 X 5.4 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>15</td>
                    <td>VIII-A </td>
                    <td>40</td>
                    <td>5.53 X 5.4 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>16</td>
                    <td>VIII-B </td>
                    <td>40</td>
                    <td>5.53 X 5.4 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>17</td>
                    <td>IX-A </td>
                    <td>35</td>
                    <td>5.53 X 5.4 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>18</td>
                    <td>IX-B </td>
                    <td>37</td>
                    <td>5.53 X 5.4 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>19</td>
                    <td>X-A </td>
                    <td>35</td>
                    <td>5.53 X 5.4 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>20</td>
                    <td>X-B</td>
                    <td>31</td>
                    <td>5.53 X 5.4 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>21</td>
                    <td>XI-A</td>
                    <td>24</td>
                    <td>7.5 X 7.4 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>22</td>
                    <td>XI-B</td>
                    <td>12</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>23</td>
                    <td>XII-A</td>
                    <td>26</td>
                    <td>7.5 X 7.4 m</td>
                    <td></td>
                </tr>
                <tr>
                    <td>24</td>
                    <td>XII-B </td>
                    <td>18</td>
                    <td>5.44 X 5.19 m</td>
                    <td></td>
                </tr>
            </tbody>
        </Table>
    );
}

export default Studentsandarea;
