import React from "react";
import { Table } from "react-bootstrap";

function Resultclassxii() {
  return (
    <Table className="table table-bordered">
      <thead>
        <tr>
          <th>S.NO.</th>
          <th>YEAR</th>
          <th colspan="2">NO. OF REGISTERED STUDENTS</th>
          <th colspan="2">NO.OF STUDENTS PASSED</th>
          <th colspan="2">PASS PERCENTAGE</th>

        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td></td>
          <td className={'semi-title'}>Science Group</td>
          <td className={'semi-title'}>Commerce Group</td>
          <td className={'semi-title'}>Science Group</td>
          <td className={'semi-title'}>Commerce Group</td>
          <td className={'semi-title'}>Science Group</td>
          <td className={'semi-title'}>Commerce Group</td>
          <td></td>
        </tr>
        <tr>
          <td>1</td>
          <td>2018-19</td>
          <td>30</td>
          <td>11</td>
          <td>30</td>
          <td>10</td>
          <td>100%</td>
          <td>90.9%</td>
          <td></td>

        </tr>
        <tr>
          <td>2</td>
          <td>2019-20</td>
          <td>26</td>
          <td>18</td>
          <td>26</td>
          <td>16</td>
          <td>100%</td>
          <td>88.8%</td>
          <td></td>

        </tr>
        <tr>
          <td>3</td>
          <td>2020-21</td>
          <td>24</td>
          <td>10</td>
          <td>24</td>
          <td>10</td>
          <td>100%</td>
          <td>100%</td>
          <td></td>

        </tr>
        {/* <tr>
                    <td>2</td>
                    <td></td>
                    <td ></td>
                    <td ></td>
                    <td></td>
                    <td ></td>
                </tr>
                <tr>
                    <td>3</td>
                    <td></td>
                    <td ></td>
                    <td ></td>
                    <td></td>
                    <td ></td>
                </tr>
                <tr>
                    <td>4</td>
                    <td></td>
                    <td ></td>
                    <td ></td>
                    <td></td>
                    <td ></td>
                </tr>
                <tr>
                    <td>5</td>
                    <td></td>
                    <td ></td>
                    <td ></td>
                    <td></td>
                    <td ></td>
                </tr>
                <tr>
                    <td>6</td>
                    <td></td>
                    <td ></td>
                    <td ></td>
                    <td></td>
                    <td ></td>
                </tr> */}
      </tbody>
    </Table>
  );
}

export default Resultclassxii;
