import React from "react";
import { Table } from "react-bootstrap";

function GeneralInformation() {
  return (
    <Table className="table table-bordered">
      <thead>
        <tr>
          <th>SLNO.</th>
          <th>INFORMATION</th>
          <th>DETAILS</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>NAME OF THE SCHOOL</td>
          <td>
            Govt. Sr. Sec. Model School, BSL Project, Sundernagar T/Ship
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>AFFILIATION NO (IF APPLICABLE)</td>
          <td>620104</td>
        </tr>
        <tr>
          <td>3</td>
          <td>SCHOOL CODE (IF APPLICABLE)</td>
          <td>44301</td>
        </tr>
        <tr>
          <td>4</td>
          <td>COMPLETE ADDRESS WITH PIN CODE </td>
          <td> Govt. Sr. Sec. Model School, BSL Project, Sundernagar T/Ship</td>
        </tr>
        <tr>
          <td>5</td>
          <td>PRINCIPAL NAME &amp; QUALIFICATION</td>
          <td>Mr. Manjit Singh </td>
        </tr>
        <tr>
          <td>6</td>
          <td>SCHOOL EMAIL ID</td>
          <td>44301@cbseshiksha.in</td>
        </tr>
        <tr>
          <td>7</td>
          <td>CONTACT DETAILS (LANDLINE/MOBILE)</td>
          <td>01907-292270, 9418052070</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default GeneralInformation;
