import React from "react";
import { Table } from "react-bootstrap";

function Infrastructure() {
  return (
    <Table className="table table-bordered">
      <thead>
      <tr>
        <th>SLNO.</th>
        <th>INFORMATION</th>
        <th>DETAILS</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>
          TOTAL CAMPUS AREA OF THE SCHOOL (IN
          SQUARE MTR)
        </td>
        <td className={'text-center'}>9714 sq m</td>
      </tr>
      <tr>
        <td>2</td>
        <td>
          NO AND SIZE OF THE CLASS ROOMS (IN SQ MTR)
        </td>
        <td className={'text-center'}>730 sq m</td>
      </tr>
      <tr>
        <td>3</td>
        <td>
          NO AND SIZE OF LABORATORIES INCLUDING
          COMPUTER LABS (IN SG MTR)
        </td>
        <td className={'text-center'}>5/61.60 Sq m (each)</td>
      </tr>
      <tr>
        <td>4</td>
        <td>
          INTERNET FACILITY (Y/N)
        </td>
        <td className={'text-center'}>Y</td>
      </tr>
      <tr>
        <td>5</td>
        <td>
          NO OF GIRLS TOILETS
        </td>
        <td className={'text-center'}>10</td>
      </tr>
      <tr>
        <td>6</td>
        <td>
          NO OF BOYS TOILETS
        </td>
        <td className={'text-center'}>10</td>
      </tr>
      <tr>
        <td>7</td>
        <td>
          LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL
        </td>
        <td className={'text-center'}>-</td>
      </tr>
      </tbody>
    </Table>
  );
}

export default Infrastructure;
