import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import { Styles } from "./styles/campusTour.js";

class CampusTour extends Component {
    state = {
        secTitle: "Let's Explore The Campus.",
    }

    render() {
        return (
            <Styles>
                {/* Campus Tour */}
                <section className="campus-tour">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>{this.state.secTitle}</h4>
                                </div>
                            </Col>
                            <Col lg="2" md="3">
                                <div className="tour-box">
                                    <ModalImage small={process.env.PUBLIC_URL + "/assets/images/local/localGallery/1.jpeg"} large={process.env.PUBLIC_URL + "/assets/images/local/localGallery/1.jpeg"} alt="" />
                                </div>
                                <div className="tour-box">
                                    <ModalImage small={process.env.PUBLIC_URL + "/assets/images/local/localGallery/3.jpeg"} large={process.env.PUBLIC_URL + "/assets/images/local/localGallery/3.jpeg"} alt="" />
                                </div>
                            </Col>
                            <Col lg="4" md="6">
                                <Row>
                                    <Col lg="6" md="6">
                                        <div className="tour-box">
                                            <ModalImage small={process.env.PUBLIC_URL + "/assets/images/local/localGallery/5.jpeg"} large={process.env.PUBLIC_URL + "/assets/images/local/localGallery/5.jpeg"} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <div className="tour-box">
                                            <ModalImage small={process.env.PUBLIC_URL + "/assets/images/local/localGallery/7.jpeg"} large={process.env.PUBLIC_URL + "/assets/images/local/localGallery/7.jpeg"} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="tour-box">
                                            <ModalImage small={process.env.PUBLIC_URL + "/assets/images/local/localGallery/9.jpeg"} large={process.env.PUBLIC_URL + "/assets/images/local/localGallery/9.jpeg"} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="2" md="3">
                                <div className="tour-box">
                                    <ModalImage small={process.env.PUBLIC_URL + "/assets/images/local/localGallery/11.jpeg"} large={process.env.PUBLIC_URL + "/assets/images/local/localGallery/11.jpeg"} alt="" />
                                </div>
                            </Col>
                            <Col lg="4" md="12">
                                <Row>
                                    <Col lg="12" md="6">
                                        <div className="tour-box">
                                            <ModalImage small={process.env.PUBLIC_URL + "/assets/images/local/localGallery/13.jpeg"} large={process.env.PUBLIC_URL + "/assets/images/local/localGallery/13.jpeg"} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="6" md="3">
                                        <div className="tour-box">
                                            <ModalImage small={process.env.PUBLIC_URL + "/assets/images/local/localGallery/15.jpeg"} large={process.env.PUBLIC_URL + "/assets/images/local/localGallery/15.jpeg"} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="6" md="3">
                                        <div className="tour-box">
                                            <ModalImage small={process.env.PUBLIC_URL + "/assets/images/local/localGallery/17.jpeg"} large={process.env.PUBLIC_URL + "/assets/images/local/localGallery/17.jpeg"} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default CampusTour
