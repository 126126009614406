import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/testimonialSlider.js";
import { res } from '../data/apidata';

class TestimonialSlider extends Component {
    render() {
        const settings = {
            slidesPerView: 1,
            loop: false,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 1
                },
                992: {
                    slidesPerView: 1
                }
            }
        };

        return (
            <Styles>
                {/* Testimonial Slider */}
                <section className="testimonial-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${res.testimonials.backgroundImage})` }}>
                    <Container>
                        <Row>
                            <Col md="12" className="testimonial-slider">
                                <Swiper {...settings}>
                                    {
                                        res.testimonials.dataList.map((data, i) => (
                                            <div className="slider-item" key={i}>
                                                <div className="desc">
                                                    <h5>{data.heading}</h5>
                                                    <p dangerouslySetInnerHTML={{ __html:  data.comment}} />
                                                </div>
                                                <div className="writer">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/${data.image}`} className="slider-image" alt={data.image} />
                                                    <h6>{data.user}</h6>
                                                    <p>{data.title}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default TestimonialSlider
